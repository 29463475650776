<template>
	<main class="page-container" id="join-intro">
		<section class="mbrp-section">
			<div class="container">
				<header class="mbrp-header">
					<h2 class="title is-large">회원 가입하기</h2>
				</header>
				<div class="mbrp-body">
					<ul class="join-intro">
						<li>
							<div class="join-intro-circle">
								<span class="icon-wrap"><i class="icon-join-integ"></i></span>
								<span class="join-intro-text">
									<strong>통합기업회원</strong>
									<em>중소기업, 소상공인</em>
								</span>
								<router-link to="/join/integrated" class="button-default is-primary is-large is-rounded">가입하기</router-link>
							</div>
						</li>
						<li>
							<div class="join-intro-circle">
								<span class="icon-wrap"><i class="icon-join-general"></i></span>
								<span class="join-intro-text">
									<strong>일반회원</strong>
									<em>예비창업자 등 일반사용자</em>
								</span>
								<router-link to="/join/general" class="button-default is-primary is-large is-rounded">가입하기</router-link>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</section>
	</main>
</template>
<script>
import { mapGetters } from 'vuex';
import DropDown from '@/components/common/DropDown';

export default {
	name: 'Join',
	components: {},
	computed: {
		...mapGetters('auth', ['session', 'isAuth']),
		...mapGetters('common', ['isMobile']),
	},
	watch: {},
	data: () => ({}),
	mounted() {},
	methods: {},
	destroyed() {},
};
</script>
